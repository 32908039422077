import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdCheckboxOutline } from "react-icons/io";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getWedThursDates } from "../utils"
import {
  bookVehicles,
  recurringBookVehicles,
  bookingDetails,
} from "../redux/action/schoolAction";
import DocumentPreview from "./DocumentPreview";
import { RiDeleteBin5Line } from "react-icons/ri";

const initialState = {
  booking_type: "",
  start_date: new Date(),
  end_date: "",
  shift_startime: "",
  shift_endtime: "",
  booking_repeat: "",
  selectedOption: "",
  total_rate: 0,
  errors: {isValid:true},
  route_detail: "",
  route_preview: "",
  route_preview_name: "",
  destination_address: "",
};

const BusBooking = ({ onClose, fareRate }) => {
  const dispatch = useDispatch();
  const [activeDays, setActiveDays] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [iState, updateState] = useState(initialState);
  const detailData = useSelector((state) => state.school);
  const { bookingDetailDatas } = detailData;

  let driver_vehicle_id = localStorage.getItem("driver_vehicle_id");
  const handleModalClose = () => {
    onClose();
  };
  const [selected, setSelected] = useState("OneTime");

  const [visible, setVisible] = React.useState(false);

  const {
    start_date,
    end_date,
    shift_startime,
    shift_endtime,
    booking_repeat,
    selectedOption,
    total_rate,
    errors,
    route_detail,
    route_preview,
    route_preview_name,
    destination_address,
  } = iState;

  const changeHandler = (e) => {
    setSelected(e.target.value);
    updateState({
      start_date: new Date(),
      end_date: "",
      shift_startime: "",
      shift_endtime: "",
      booking_repeat: "Everyday",
      selectedOption: "",
      total_rate: "",
      route_detail: "",
      route_preview_name: "",
      destination_address: "",
      isOneTimeFormValid:true,
      errors:{isValid:true}
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "shift_endtime") {
        if (!iState.shift_startime) {
            updateState({
                ...iState,
                errors: {
                    shift_endtimeEmpty: "Please select start time first"
                  },
                });
                return
              }
        if (iState.shift_startime < value) {
              updateState({
                ...iState,
                [name]: value,
              });
      } else {
        updateState({
          ...iState,
          errors: {
            shift_endtimeEmpty: "You can't select the previous time"
          },
        });
      }
      return
    }
    updateState({
      ...iState,
      [name]: value,
    });
  };
  useEffect(() => {
    if (selected === "OneTime") {
      const startTimeParts = shift_startime.split(":");
      const endTimeParts = shift_endtime.split(":");
      const startTimeInMinutes =
        parseInt(startTimeParts[0]) * 60 + parseInt(startTimeParts[1]);
      const endTimeInMinutes =
        parseInt(endTimeParts[0]) * 60 + parseInt(endTimeParts[1]);
      let totalMin = endTimeInMinutes - startTimeInMinutes;
      let rate = fareRate / 60
      let ratePerMin = rate
      const totalRes = totalMin * ratePerMin;
      updateState({
        ...iState,
        total_rate: totalRes,
      });
    } else {
      let totalDays = 0
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);
      const startTimeParts = shift_startime.split(":");
      const endTimeParts = shift_endtime.split(":");
      if (booking_repeat === "Everyday") {
        const dateWithMinusOneMin = startDateObj.setHours(0, 0, -1, 0);
        totalDays = Math.ceil(
          (endDateObj - dateWithMinusOneMin) / (1000 * 60 * 60 * 24)
        );
      } else {
        totalDays = getWedThursDates(moment(startDateObj).format("YYYY-MM-DD"), moment(endDateObj).format("YYYY-MM-DD"), activeDays);
      }

      const startTimeInMinutes = parseInt(startTimeParts[0]) * 60 + parseInt(startTimeParts[1]);
      const endTimeInMinutes = parseInt(endTimeParts[0]) * 60 + parseInt(endTimeParts[1]);
      let totalMin = endTimeInMinutes - startTimeInMinutes;
      let rate = fareRate / 60
      let ratePerMin = rate
      let ratePerDay = totalMin * ratePerMin
      const totalRes = ratePerDay * totalDays;
      updateState({
        ...iState,
        total_rate: totalRes,
      });
    }
  }, [shift_startime, shift_endtime, start_date, end_date, selectedOption, activeDays]);

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      selectedOption: value,
      booking_repeat: value,
    });
  };

  const toggleDay = (day) => {
    if (activeDays.includes(day)) {
      setActiveDays(activeDays.filter((item) => item !== day));
    } else {
      setActiveDays([...activeDays, day]);
    }
  };

  const handleChangeDate = (data) => {
    updateState({
      ...iState,
      start_date: data,
    });
  };

  const routeFileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        const preview = URL.createObjectURL(file)
        updateState({
          ...iState,
          route_detail: file,
          route_preview: preview,
          route_preview_name: file.name
        });
      } else {
        toast.error("Only formats are allowed: xls, xlsx files", {
          position: toast?.POSITION?.TOP_RIGHT,
        });
      }
    }
  }

  const clearRoute = () => {
    updateState({
      ...iState,
      route_detail: "",
      route_preview: "",
      route_preview_name: ""
    });
  }

  const handleChangeEndDate = (data) => {
  
    updateState({
      ...iState,
      end_date: data,
    });
  };

  useEffect(() => {
    const getProfileData = async () => {
      try {
        await dispatch(bookingDetails());
      } catch (error) {
        console.error("Error fetching user profile details:", error);
      }
    };

    getProfileData();
  }, []);

  let handleValidationOneTime = () => {
    let start_dateEmpty = "";
    let shift_startimeEmpty = "";
    let shift_endtimeEmpty = "";
    let route_detailEmpty = "";
    let formIsValid = true;

    if (typeof start_date === "string" && !start_date.trim()) {
      start_dateEmpty = "Please select  start date.";
      formIsValid = false;
    }
    if (!shift_startime.trim()) {
      shift_startimeEmpty = "Please select shift start time.";
      formIsValid = false;
    }
    if (!shift_endtime.trim()) {
      shift_endtimeEmpty = "Please select shift end time.";
      formIsValid = false;
    }
    if (route_detail ? !route_detail?.name.trim() : !route_detail.trim()) {
      route_detailEmpty = "Please select  route detail.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        start_dateEmpty,
        shift_startimeEmpty,
        shift_endtimeEmpty,
        route_detailEmpty,
        isValid:formIsValid
      },
    });
    return formIsValid;
  };

  let handleValidation = () => {
    let start_dateEmpty = "";
    let end_dateEmpty = "";
    let shift_startimeEmpty = "";
    let shift_endtimeEmpty = "";
    let booking_repeatEmpty = "";
    let route_detailEmpty = "";
    let formIsValid = true;

    if (typeof start_date === "string" && !start_date.trim()) {
      start_dateEmpty = "Please select  start date.";
      formIsValid = false;
    }
    if (typeof end_date === "string" && !end_date.trim()) {
      end_dateEmpty = "Please select end date.";
      formIsValid = false;
    }
    if (!shift_startime.trim()) {
      shift_startimeEmpty = "Please select sift start time.";
      formIsValid = false;
    }
    if (!shift_endtime.trim()) {
      shift_endtimeEmpty = "Please select sift end time.";
      formIsValid = false;
    }
    if (!booking_repeat.trim()) {
      booking_repeatEmpty = "Please select  booking repeat.";
      formIsValid = false;
    }
    if (route_detail ? !route_detail?.name.trim() : !route_detail.trim()) {
      route_detailEmpty = "Please select route detail.";
      formIsValid = false;
    }

    updateState({
      ...iState,
      errors: {
        start_dateEmpty,
        end_dateEmpty,
        shift_startimeEmpty,
        shift_endtimeEmpty,
        booking_repeatEmpty,
        route_detailEmpty,
        isValid:formIsValid

      },
    });
    return formIsValid;
  };

  const handleSubmitOneTime = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidationOneTime();
    if (formIsValid) {
      setIsSubmitLoading(true)
      const data = {
        booking_type: "one_time",
        driver_vehicle_id,
        start_date,
        shift_startime,
        shift_endtime,
        fareRate,
        route_detail,
        total_rate: Number(total_rate).toFixed(2),
        destination_address
      };
      try {
        const res = await dispatch(bookVehicles(data));
        if (res) {
          setIsSubmitLoading(false)
          dispatch(bookingDetails(res?.id));
          setVisible(!visible);
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.message);
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const handleSubmitRecurring = async (e) => {
    e.preventDefault();
    let formIsValid = handleValidation();
    if (formIsValid) {
      setIsSubmitLoading(true)

      const activeDaysPlusOne = activeDays.map(item => item+1)
      const data = {
        driver_vehicle_id,
        booking_type: "recurring",
        start_date,
        end_date,
        shift_startime,
        shift_endtime,
        booking_repeat,
        fareRate,
        weeks: activeDaysPlusOne,
        route_detail,
        total_rate: Number(total_rate).toFixed(2),
        destination_address
      };
      try {
        if(data.weeks.length == 0){
          toast.error("Select atleast One Day", {
            position: toast?.POSITION?.TOP_RIGHT,
          });
          setIsSubmitLoading(false)
          return
        }
 
        const res = await dispatch(recurringBookVehicles(data));
        if (res) {
          setIsSubmitLoading(false)
          dispatch(bookingDetails(res?.id));
          setVisible(!visible);
        }
      } catch (err) {
        setIsSubmitLoading(false)
        if (err.response && err.response.status === 400) {
          toast.error(err?.response?.data?.message);
        } else {
          console.log(`error>>>> ${err}`);
        }
      }
    }
  };

  const handleClickOneTimeInnerModel = () =>{
    if(!errors.isValid){
      handleValidationOneTime()
    }
  }

  const handleClickRecurringInnerModel = () =>{
    if(!errors.isValid){
      handleValidation()
    }
  }
  
  return (
    <>
      <div className="popupBox p-3 p-md-5">
        <div style={{ display: visible ? "none" : "block" }} >
          <h2 className="text-center fpHd mb-3">Reservations</h2>
          <div className="mb-2 p-2">
            <label className="me-4">
              <span className="radioWrapper">
                <input
                  className="radioInpt"
                  type="radio"
                  value="OneTime"
                  name="bookingType"
                  id="OneTime"
                  checked={selected === "OneTime"}
                  onChange={changeHandler}
                />
                <i className="bg"></i>
              </span>{" "}
              One Time
            </label>
            <label>
              <span className="radioWrapper">
                <input
                  className="radioInpt"
                  type="radio"
                  value="Recurring"
                  name="bookingType"
                  id="Recurring"
                  checked={selected === "Recurring"}
                  onChange={changeHandler}
                />
                <i className="bg"></i>
              </span>{" "}
              Recurring
            </label>
          </div>
          <div aria-hidden={selected !== "OneTime" ? true : false} onClick={handleClickOneTimeInnerModel}>
            <ul className="form p-0 m-0 d-flex flex-wrap">
              <li className="col-12">
                <label className="filedHd">Date</label>
                <div className="position-relative">
                  <DatePicker
                    className="formInput"
                    selected={start_date}
                    onChange={(date) => handleChangeDate(date)}
                  />{" "}
                  <FaCalendarAlt className="calederIcon" />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.start_dateEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">Start Time</label>
                <div className="position-relative">
                  <input
                    type="time"
                    className="formInput"
                    name="shift_startime"
                    value={shift_startime}
                    onChange={handleChange}
                  />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.shift_startimeEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">End Time</label>
                <div className="position-relative">
                  <input
                    type="time"
                    className="formInput"
                    name="shift_endtime"
                    value={shift_endtime}
                    onChange={handleChange}
                  />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.shift_endtimeEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">Route</label>
                {route_detail &&
                  <span
                    className="m-2"
                    onClick={clearRoute}
                  >
                    <RiDeleteBin5Line />
                  </span>}
                {!route_detail &&
                  <>
                    <div className="uplaodBox d-flex align-items-center">
                      <Button className="btn">
                        Upload
                        <input
                          type="file"
                          className="file"
                          onChange={routeFileHandler}
                          onClick={(e) => (e.target.value = null)}
                          accept=".xls, .xlsx"
                        />
                      </Button>
                    </div>
                    <span style={{ color: "red" }}>
                      {errors && errors?.route_detailEmpty}
                    </span>
                  </>
                }
                {/* <DocumentPreview preview={route_preview} /> */}
                {route_preview && <div className="uplaodBox d-flex align-items-center">
                  {route_preview_name}
                </div>}
              </li>

              <li className="col-6">
                <label className="filedHd">Ride Type</label>
                <select
                  className="formSelect"
                  name="state"
                  // value={state}
                  // onChange={handleChange}
                >
                  <option>School to Home</option>
                  <option>Home to School</option>
                  {/* {stateList?.length > 0
                    ? stateList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""} */}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.stateEmpty}
                </span>
              </li>

              <li className="col-12">
                <label className="filedHd">Destination address</label>
                <textarea
                  type="text"
                  className="formInput"
                  name="destination_address"
                  onChange={handleChange}
                  value={destination_address}
                />
              </li>



              <li className="col-12">
                <label className="filedHd">Fare Rate</label>
                <input
                  type="text"
                  className="formInput"
                  value={`$ ${total_rate ? total_rate.toFixed(2) : "00.00"}`}
                  readOnly
                />
              </li>
              <li className="d-flex col-12">
                <div className="col-6 pe-2 pt-2">
                  <Button
                    className="smallBtn full cancel"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-6 ps-2 pt-2">
                  <Button
                    className="smallBtn full"
                    onClick={handleSubmitOneTime}
                  >
                    {isSubmitLoading ? <div class="spinner-border" role="status">
                      <span class="sr-only p-0"></span>
                    </div> : "Book"}
                  </Button>
                </div>
              </li>
            </ul>
          </div>
          <div aria-hidden={selected !== "Recurring" ? true : false} onClick={handleClickRecurringInnerModel}>
            <ul className="form p-0 m-0 d-flex flex-wrap">
              <li className="col-6">
                <label className="filedHd">Start Date</label>
                <div className="position-relative">
                  <DatePicker
                    className="formInput"
                    selected={start_date}
                    onChange={(date) => handleChangeDate(date)}
                  />{" "}
                  <FaCalendarAlt className="calederIcon" />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.start_dateEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">End Date</label>
                <div className="position-relative">
                  <DatePicker
                    className="formInput"
                    selected={end_date}
                    onChange={(date) => handleChangeEndDate(date)}
                    minDate={start_date}
                  />{" "}
                  <FaCalendarAlt className="calederIcon" />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.end_dateEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">Start Time</label>
                <div className="position-relative">
                  <input
                    type="time"
                    className="formInput"
                    name="shift_startime"
                    value={shift_startime}
                    onChange={handleChange}
                  />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.shift_startimeEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">End Time</label>
                <div className="position-relative">
                  <input
                    type="time"
                    className="formInput"
                    name="shift_endtime"
                    value={shift_endtime}
                    onChange={handleChange}
                  />
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.shift_endtimeEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">Repeat Every</label>
                <div className="d-flex">
                  {/* <input
                    type="number"
                    className="formInput"
                    name="shift_startime"
                    value={shift_startime}
                    onChange={handleChange}
                  /> */}
                  <select
                    className="formSelect"
                    name="booking_repeat"
                    value={booking_repeat}
                    onChange={handleSelectChange}
                  >
                    <option value="Everyday">Everyday</option>
                    {/* <option value="Biweekly">Biweekly</option>
                    <option value="Monthly">Monthly</option> */}
                    <option value="Weekly">Weekly</option>
                  </select>
                </div>
                <span style={{ color: "red" }}>
                  {errors && errors?.booking_repeatEmpty}
                </span>
              </li>
              <li className="col-6">
                <label className="filedHd">Route</label>
                {route_detail &&
                  <span
                    className="m-2"
                    onClick={clearRoute}
                  >
                    <RiDeleteBin5Line />
                  </span>}
                {!route_detail &&
                  <>
                    <div className="uplaodBox d-flex align-items-center">
                      <Button className="btn">
                        Upload
                        <input
                          type="file"
                          className="file"
                          onChange={routeFileHandler}
                          onClick={(e) => (e.target.value = null)}
                          accept=".xls, .xlsx"

                        />
                      </Button>
                    </div>
                    <span style={{ color: "red" }}>
                      {errors && errors?.route_detailEmpty}
                    </span>
                  </>
                }
                {/* <DocumentPreview preview={route_preview} /> */}
                {route_preview && <div className="uplaodBox d-flex align-items-center">
                  {route_preview_name}
                </div>}
              </li>
              <li className="col-12">
                <label className="filedHd">State/Province</label>
                <select
                  className="formSelect"
                  name="state"
                  // value={state}
                  // onChange={handleChange}
                >
                  <option>School to Home</option>
                  <option>Home to School</option>
                  {/* {stateList?.length > 0
                    ? stateList?.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                    : ""} */}
                </select>
                <span style={{ color: "red" }}>
                  {errors && errors?.stateEmpty}
                </span>
              </li>
              {selectedOption === "Weekly" && (
                <li className="col-12">
                  <ul className="d-flex m-0 p-0 weekList justify-content-between">
                    <li
                      className={activeDays.includes(0) ? "active" : ""}
                      onClick={() => toggleDay(0)}
                    >
                      Sun
                    </li>
                    <li
                      className={activeDays.includes(1) ? "active" : ""}
                      onClick={() => toggleDay(1)}
                    >
                      Mon
                    </li>
                    <li
                      className={activeDays.includes(2) ? "active" : ""}
                      onClick={() => toggleDay(2)}
                    >
                      Tue
                    </li>
                    <li
                      className={activeDays.includes(3) ? "active" : ""}
                      onClick={() => toggleDay(3)}
                    >
                      Wed
                    </li>
                    <li
                      className={activeDays.includes(4) ? "active" : ""}
                      onClick={() => toggleDay(4)}
                    >
                      Thu
                    </li>
                    <li
                      className={activeDays.includes(5) ? "active" : ""}
                      onClick={() => toggleDay(5)}
                    >
                      Fri
                    </li>
                    <li
                      className={activeDays.includes(6) ? "active" : ""}
                      onClick={() => toggleDay(6)}
                    >
                      Sat
                    </li>
                  </ul>
                </li>
              )}

              <li className="col-12">
                <label className="filedHd">Destination address</label>
                <textarea
                  type="text"
                  className="formInput"
                  name="destination_address"
                  onChange={handleChange}
                  value={destination_address}
                />
              </li>

              <li className="col-12">
                <label className="filedHd">Fare Rate</label>
                <input
                  type="text"
                  className="formInput"
                  value={`$ ${total_rate ? total_rate !== "" ? total_rate.toFixed(2) : total_rate : "00.00"}`}
                  readOnly
                />
              </li>
              <li className="d-flex col-12">
                <div className="col-6 pe-2 pt-2">
                  <Button
                    className="smallBtn full cancel"
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-6 ps-2 pt-2">
                  <Button
                    className="smallBtn full"
                    onClick={handleSubmitRecurring}
                  >
                    {isSubmitLoading ? <div class="spinner-border" role="status">
                      <span class="sr-only p-0"></span>
                    </div> : "Book"}
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ display: visible ? "block" : "none" }}>
          <div className="text-center mb-3 checkIcon">
            <IoMdCheckboxOutline />
          </div>
          <div className="text-center mb-3 deleteHd">
            School Bus Booking Completed Successfully!
          </div>
          <div className="bookingDetBox p-3 p-md-4 mb-4">
            <span className="hd pb-2">Booking Details</span>
            <ul className="detInfo p-0 m-0">
              <li>
                <span>Vendor:</span>{" "}
                {`${bookingDetailDatas &&
                  bookingDetailDatas?.vehicle?.vehicle?.user?.full_name
                  }`}
              </li>
              <li>
                <span>Vehicle:</span>{" "}
                {`${bookingDetailDatas &&
                  bookingDetailDatas?.vehicle?.vehicle?.make?.name
                  } ${bookingDetailDatas &&
                  bookingDetailDatas?.vehicle?.vehicle?.model?.name
                  }`}
              </li>
              <li>
                <span>Vehicle License Plate:</span>
                {bookingDetailDatas &&
                  bookingDetailDatas?.vehicle?.vehicle.license_number}
              </li>
              <li>
                <span>Seats:</span>{" "}
                {bookingDetailDatas &&
                  bookingDetailDatas?.vehicle?.vehicle.seat}
              </li>
              <li>
                <span>Fare Rate:</span> $
                {bookingDetailDatas && bookingDetailDatas?.total_rate}
              </li>
              <li>
                <span>From:</span> {`${bookingDetailDatas && bookingDetailDatas?.vehicle?.vehicle?.user?.address}`}
              </li>
              <li>
                <span>Destination:</span> {bookingDetailDatas && bookingDetailDatas?.destination_address ? bookingDetailDatas && bookingDetailDatas?.destination_address : bookingDetailDatas && bookingDetailDatas?.school?.address}
              </li>
              <li>
                <span>
                  Date:
                  {moment(
                    bookingDetailDatas && bookingDetailDatas?.start_date
                  ).format("MMMM-DD-YYYY")}
                </span>
              </li>
              <li>
                <span>Time:</span>{" "}
                {bookingDetailDatas && bookingDetailDatas?.shift_startime} -{" "}
                {bookingDetailDatas && bookingDetailDatas?.shift_endtime}
              </li>
            </ul>
          </div>
          <div className="text-center">
            <Button className="smallBtn cancel me-3" onClick={handleModalClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusBooking;
